import { axios, setToken } from '../../utils/axios';

const trendingCategoriesAPI = {
  getTrendingCategories() {
    setToken();
    return axios.get('/admin/v1/popularCategories');
  },
  addToTrendingCategories(IDs: number[]) {
    setToken();
    return axios.post('/admin/v1/popularCategories', { category_ids: IDs });
  },
  getTrendingCategory(id: number) {
    setToken();
    return axios.post(`/admin/v1/popularCategories/${id}`);
  },
  removeFromTrendingCategories(id: number) {
    setToken();
    return axios.delete(`/admin/v1/popularCategories/${id}`);
  },
  updateTrendingCategory(id: number, status: string) {
    setToken();
    return axios.post(`/admin/v1/popularCategories/${id}`, { status });
  },
  moveTrendingCategoryBefore(movedId: number, beforeId: number) {
    setToken();
    return axios.post(`/admin/v1/popularCategories/${movedId}/moveBefore/${beforeId}`);
  },
  moveTrendingCategoryAfter(movedId: number, afterId: number) {
    setToken();
    return axios.post(`/admin/v1/popularCategories/${movedId}/moveAfter/${afterId}`);
  },
};

export { trendingCategoriesAPI };
