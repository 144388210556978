import { RoutesBlock } from '@nebo-team/vobaza.layout.navigition/dist';

export const routes = [
  {
    id: 0,
    label: 'Пользователи',
    href: '/users/buyers',
    notAllowedRoles: ['SUPER_ADMIN', 'MAIN_CONTENT_MANAGER', 'CONTENT_MANAGER'],
    routes: [
      {
        value: 'Покупатели',
        link: '/users/buyers',
      },
    ],
  },
  {
    id: 1,
    label: 'Пользователи и роли',
    href: '/users',
    notAllowedRoles: ['MAIN_CONTENT_MANAGER', 'CONTENT_MANAGER', 'CALL_CENTER_OPERATOR', 'CALL_CENTER_MASTER'],
    routes: [
      {
        value: 'Администраторы',
        link: '/users/admins',
      },
      {
        value: 'Мерчанты',
        link: '/users/merch',
      },
      {
        value: 'Покупатели',
        link: '/users/buyers',
      },
      {
        value: 'Журнал событий',
        link: '/users/events',
      },
    ],
  },
  {
    id: 2,
    label: 'Товары',
    href: '/goods',
    notAllowedRoles: ['CALL_CENTER_OPERATOR', 'CALL_CENTER_MASTER'],
    routes: [
      {
        value: 'Категории товаров',
        link: '/goods/category',
      },
      {
        value: 'Товары',
        link: '/goods',
        isExact: true,
      },
      {
        value: 'Характеристики товаров',
        link: '/goods/params',
      },
      {
        value: 'Этикетки товаров',
        link: '/goods/stickers',
      },
      {
        value: 'Загрузить инфомодель',
        link: '/goods/upload-infomodel',
      },
      {
        value: 'Загрузить товары',
        link: '/goods/upload-products',
      },
      {
        value: 'Загрузить теги',
        link: '/goods/upload-tags',
      },
      {
        value: 'Загрузить сопостовление остатков',
        link: '/goods/upload-comparison',
      },

      {
        value: 'Выгрузить товары',
        link: '/goods/export-products',
      },

      {
        value: 'История всех действий',
        link: '/goods/tasks-history',
      },
      {
        value: 'Создать фид',
        link: '/goods/feed/create',
      },
      {
        value: 'Сгенерировать штрихкоды',
        link: '/goods/barcodes',
      },
    ],
  },
  {
    id: 3,
    label: 'Услуги',
    href: '/services',
    notAllowedRoles: ['MAIN_CONTENT_MANAGER', 'CONTENT_MANAGER', 'CALL_CENTER_OPERATOR', 'CALL_CENTER_MASTER'],
    routes: [
      {
        value: 'Услуги',
        link: '/services',
        isExact: true,
      },
      {
        value: 'Категории услуг',
        link: '/services/category',
      },
    ],
  },
  {
    id: 4,
    label: 'Склады и остатки',
    href: '/storage',
    notAllowedRoles: ['MAIN_CONTENT_MANAGER', 'CONTENT_MANAGER', 'CALL_CENTER_OPERATOR', 'CALL_CENTER_MASTER'],
    routes: [],
  },
  {
    id: 5,
    label: 'Заказы',
    href: '/orders',
    notAllowedRoles: ['MAIN_CONTENT_MANAGER', 'CONTENT_MANAGER'],
    routes: [],
  },
  {
    id: 6,
    label: 'Управление',
    href: '/management',
    notAllowedRoles: ['CONTENT_MANAGER', 'CALL_CENTER_OPERATOR', 'CALL_CENTER_MASTER'],
    routes: [
      {
        value: 'Меню',
        link: '/management/menu',
      },
      {
        value: 'Баннеры',
        link: '/management/banners',
      },
      {
        value: 'Популярные категории',
        link: '/management/trending-categories',
      },
      {
        value: 'Коллекции сезона',
        link: '/management/collections',
      },
      {
        value: 'Страницы-хабы',
        link: '/management/hubs',
      },
      {
        value: 'Кэш',
        link: '/management/cache',
      },
    ],
  },
  {
    id: 7,
    label: 'Заявки с сайта',
    href: '/calls',
    notAllowedRoles: ['MAIN_CONTENT_MANAGER', 'CONTENT_MANAGER'],
    routes: [
      {
        value: 'Обратный звонок ',
        link: '/calls',
        isExact: true,
      },
      { value: 'Заявки на партнерство', link: '/calls/partners' },
      {
        value: 'Обратная связь',
        link: '/calls/feedback',
      },
      {
        value: 'Рассылка',
        link: '/calls/mailing',
      },
    ],
  },
  {
    id: 8,
    label: 'Словари',
    href: '/dictionaries',
    notAllowedRoles: [
      'ANALYTIC',
      'CONTENT_MANAGER',
      'MAIN_CONTENT_MANAGER',
      'MERCHANT_MANAGER',
      'CALL_CENTER_OPERATOR',
    ],
    routes: [
      {
        value: 'Источники заказов',
        link: '/dictionaries/order-sources',
      },
    ],
  },
] as any[];

export const goodsMerchantRoutes = (merchantId: string) =>
  [
    {
      id: 1,
      title: '',
      routes: [
        {
          value: 'Все товары',
          link: `/goods/merchant/${merchantId || '1'}`,
          isExact: true,
        },
        {
          value: 'Добавить товар',
          link: `/goods/merchant/${merchantId || '1'}/add`,
        },
        {
          value: 'Обновить товары',
          link: `/goods/merchant/${merchantId || '1'}/update`,
        },
        {
          value: 'История обновлений',
          link: `/goods/merchant/${merchantId || '1'}/history`,
        },
        {
          value: 'Сертификаты',
          link: `/goods/merchant/${merchantId || '1'}/certificates`,
        },
      ],
    },
  ] as RoutesBlock[];

export const goodsMerchantUpdateTabs = [
  {
    value: 'Товары',
    code: 'update',
  },
  {
    value: 'Изображения',
    code: 'update/images',
  },
];
