import type {
  IAcceptStatusPaymentType,
  IMessagePaymentUrlType,
  IPaymentForm,
  IPaymentReceipt,
  IPaymentsRefund,
  IPostOrder,
  ICrossdocChangeFieldBody
} from 'src/models/IOrder';
import type { ICalculateOrder, IGetOrder, IProductsPriceData } from './types';
import { AxiosPromise, AxiosRequestConfig } from 'axios';
import { axios, setToken } from '../../utils/axios';
import { ISellersOrder } from 'src/models/IOrder/ISellersOrders';

interface IChangeCrossDockSupplyDate extends ICrossdocChangeFieldBody {
  "date": string
}
interface IChangeCrossDockSupplyByMerchant extends ICrossdocChangeFieldBody {
  "by_merchant": boolean
}

const ordersAPI = {
  getOrderList(params: { [key: string]: string | number | Date | null }) {
    setToken();
    return axios.get('admin/v1/orders', {
      params,
    });
  },
  getOrderInfo(id: string) {
    setToken();
    return axios.get<{ data: IGetOrder }>(`admin/v2/orders/${id}`);
  },
  createOrder(data: IPostOrder) {
    setToken();
    return axios.post('admin/v2/orders', data);
  },
  updateOrder(id: number, data: IPostOrder) {
    setToken();
    return axios.post(`admin/v2/orders/${id}`, data);
  },
  // Доставка в заказе
  getDeliveryMethods(params: { address: string }) {
    setToken();
    return axios.get(`admin/v1/orders/deliveryVariants`, { params });
  },
  // Получение цены товаров
  getProductsPrices(data: IProductsPriceData) {
    setToken();
    return axios.post('admin/v1/orders/calculate/products', data);
  },
  // Расчет стоимости заказа
  getCalculatePrice(data: ICalculateOrder) {
    setToken();
    return axios.post('admin/v1/orders/calculate/totalToPay', data);
  },
  // Получение ссылки на оплату через сбербанк
  getPaymentLink(orderId: number) {
    setToken();
    return axios.post(`admin/v1/orders/${orderId}/payment/sberbank`);
  },
  // Редактирование массива платежей
  updateOrderPayment(orderId: number, data: { payments: IPaymentForm[] }) {
    setToken();
    return axios.post(`admin/v1/orders/${orderId}/payments/update`, data);
  },
  // Обновление ссылки на оплату
  refreshUrlPayment(orderId: number, paymentId: number, data: { expired_time: number }) {
    setToken();
    return axios.post(`admin/v1/orders/${orderId}/payments/${paymentId}/refreshUrl`, data);
  },
  // Отправка смс или email об оплате
  sendMessagePaymentUrl(orderId: number, paymentId: number, param: { notification_type: IMessagePaymentUrlType }) {
    setToken();
    return axios.post(`admin/v1/orders/${orderId}/payments/${paymentId}/sendPaymentUrl`, param);
  },
  // Подтверждение принятия платежа YOUMONEY
  approvePaymentYoumoney(orderId: number, paymentId: number, param: { action: IAcceptStatusPaymentType }) {
    setToken();
    return axios.post(`admin/v1/orders/${orderId}/payments/${paymentId}/accept`, param);
  },
  // Отмена заказа
  cancelStatusOrder(orderId: number) {
    setToken();
    return axios.post(`admin/v1/orders/${orderId}/cancel`);
  },
  getOrderPaymentReceipt(paymentId: number) {
    setToken();
    return axios.get<{ data: IPaymentReceipt }>(`admin/v1/payments/${paymentId}/receipt`);
  },
  // Возврат заказа
  refundStatusOrder(orderId: number) {
    setToken();
    return axios.post(`admin/v1/orders/${orderId}/refund`);
  },
  //Возврат платежа
  refundPayments(objectId: number, paymentId: number, body: IPaymentsRefund) {
    setToken();
    return axios.post(`admin/v1/orders/${objectId}/payments/${paymentId}/refund`, body);
  },
  //удаление платежа
  deletePayment(objectId?: number, paymentId?: number) {
    setToken();
    return axios.delete(`admin/v1/orders/${objectId}/payments/${paymentId}`);
  },
  //подтверждение доставки
  confirmDelivery({ id }: { id: number | string }) {
    setToken()
    return axios.post(`/admin/v1/orders/${id}/confirm-delivery`, { ['confirm-delivery']: true })
  },
  //изменение даты
  changeCrossDockSupplyDate({ objectId, body }: {
    objectId: number | string, body: IChangeCrossDockSupplyDate
  }) {
    setToken()
    return axios.post(`/admin/v1/orders/shipment-method/${objectId}/supply-date`, body)
  },
  //изменение cтатуса поля supply-by-merchant
  changeCrossDockSupplyByMerchant({ objectId, body }: {
    objectId: number | string, body: IChangeCrossDockSupplyByMerchant
  }) {
    setToken()
    return axios.post(`/admin/v1/orders/shipment-method/${objectId}/supply-by-merchant`, body)
  },
  //изменение менеджера на текущего
  setNewManager(objectId: number) {
    setToken()
    return axios.post(`/admin/v1/orders/${objectId}/manager`, {})
  },
  //получение xml файла
  getExportFile(config: AxiosRequestConfig): AxiosPromise<Blob> {
    setToken();
    return axios.get(`/admin/v1/orders/export`, { ...config, responseType: 'blob' })
  },
  //получение списка заказов с доставкой продавца
  getSellersOrders(params?: AxiosRequestConfig["params"]): AxiosPromise<{ data: ISellersOrder[] }> {
    setToken()
    return axios.get('/admin/v1/orders/shipment-method/seller-shipping', { params })
  },
  //изменение статуса заказа с доставкой продавца
  // /admin/v1/orders/shipment-method/{objectId}/status-up
  changeSellerStatus(objectId: number, params: AxiosRequestConfig["params"]): AxiosPromise<{ data: ISellersOrder }> {
    setToken()
    return axios.post(`/admin/v1/orders/shipment-method/${objectId}/status-up`, params)
  }
}



export { ordersAPI };
