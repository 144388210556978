import { axios, setToken } from '../utils/axios';

import { IFeedDetails } from 'src/models/IFeed';

const feedsAPI = {
  createFeed(params: IFeedDetails) {
    setToken();
    return axios.post('admin/v1/feeds', { ...params, feed_type: 'DEFAULT' });
  },
  getFeedsList(params: Record<string, string | number | string[]>) {
    setToken();
    return axios.get('admin/v1/feeds', { params });
  },
  getFeed(id: number) {
    setToken();
    return axios.get(`admin/v1/feeds/${id}`);
  },

  updateFeed(id: number, feed: IFeedDetails) {
    setToken();
    return axios.post(`admin/v1/feeds/${id}`, feed);
  },

};

export { feedsAPI };