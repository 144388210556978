import { axios } from 'shared/api/utils/axios';

interface Props {
  onSuccess?(response: any): any;
  onError?(response: any): any;
}

export const useInterceptors = ({ onSuccess, onError }: Props) => {
  axios.interceptors.response.use(
    function (response) {
      onSuccess && onSuccess(response);
      return response;
    },
    function (error) {
      onError && onError(error);
      return Promise.reject(error);
    }
  );
};
