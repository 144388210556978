import { axios, setToken } from '../../utils/axios';

const comparisonStocksAPI = {
  uploadComparisonStocks(file: FormData) {
    setToken();
    return axios.post('admin/v1/comparison-stocks/', file);
  },
};

export { comparisonStocksAPI };
