import { axios, setToken } from '../utils/axios';

import type { IAdminLogsRequest } from '../../../src/models/IAdminLogs';
import type { IMerchWorker } from '../../../src/models/IMerch';

const merchantsAPI = {
  getMerchList(params?: { [key: string]: string | number | Array<string | number> | null }) {
    setToken();
    return axios.get('admin/v1/merchants', {
      params: { ...params, limit: 200 },
    });
  },
  getMerch(merchId: string | number) {
    setToken();
    return axios.get(`admin/v1/merchants/${merchId}`);
  },
  createMerch(data: any) {
    setToken();
    return axios.post('admin/v1/merchants', data);
  },
  changeMerch(id: string | number, data: any) {
    setToken();
    return axios.post(`admin/v1/merchants/${id}`, data);
  },
  deleteMerch(id: string | number) {
    setToken();
    return axios.delete(`admin/v1/merchants/${id}`);
  },
  activateMerch(id: string | number) {
    setToken();
    return axios.post(`admin/v1/merchants/${id}/activate`);
  },
  rejectMerch(id: string | number, data: any) {
    setToken();
    return axios.post(`admin/v1/merchants/${id}/reject`, data);
  },
  createMerchEmployee(merchId: string | number, employee: IMerchWorker) {
    setToken();
    return axios.post(`admin/v1/merchants/${merchId}/users`, employee);
  },
  changeMerchEmployee(employeeId: string | number, employee: IMerchWorker) {
    setToken();
    return axios.post(`admin/v1/merchants/users/${employeeId}`, employee);
  },
  deleteMerchEmployee(employeeId: string | number) {
    setToken();
    return axios.delete(`admin/v1/merchants/users/${employeeId}`);
  },
  getMerchWorkers(merchId: string | number) {
    setToken();
    return axios.get(`admin/v1/merchants/${merchId}/users`);
  },
  getMerchLogs({ limit, offset, filters }: IAdminLogsRequest) {
    setToken();
    return axios.get(`admin/v1/logs`, {
      params: {
        limit,
        offset,
        ...filters,
      },
    });
  },
};

export { merchantsAPI };
