import { ICustomer, ICustomerAddress, ICustomerRequsetParams } from 'src/models/ICustomer';
import { axios, setToken } from '../utils/axios';

const customersAPI = {
  getCustomers(params: ICustomerRequsetParams) {
    setToken();
    return axios.get('/admin/v1/customers', { params });
  },
  createCustomer(customer: Omit<ICustomer, 'id'>) {
    setToken();
    return axios.post('/admin/v1/customers', customer);
  },
  getCustomerInfo(id: number | string) {
    setToken();
    return axios.get(`/admin/v1/customers/${id}`);
  },
  updateCustomer(id: number | string, customer: ICustomer) {
    setToken();
    return axios.post(`/admin/v1/customers/${id}`, customer);
  },
  getCustomersAddresses(id: number | string) {
    setToken();
    return axios.get(`/admin/v1/customers/${id}/addresses`);
  },
  deleteCustomersAddress(id: number | string, idAddress: number | string) {
    setToken();
    return axios.delete(`/admin/v1/customers/${id}/addresses/${idAddress}`);
  },
  updateCustomersAddress(id: number | string, idAddress: number, address: ICustomerAddress) {
    setToken();
    return axios.post(`/admin/v1/customers/${id}/addresses/${idAddress}`, address);
  },
  createCustomersAddress(id: number | string, address: ICustomerAddress) {
    setToken();
    return axios.post(`/admin/v1/customers/${id}/addresses`, address);
  },
  updateCustomersAddressDefault(id: number | string, idAddress: number | string) {
    setToken();
    return axios.post(`/admin/v1/customers/${id}/addresses/${idAddress}/default`);
  },
};

export { customersAPI };
