import { axios, setToken } from '../utils/axios';

const collectionsAPI = {
  getCollectionsList() {
    setToken();
    return axios.get(`admin/v1/collections`);
  },
  getCollectionName() {
    setToken();
    return axios.get(`admin/v1/collections/groups`)
  },
  updateCollectionName(name: string) {
    setToken();
    return axios.post(`admin/v1/collections/groups`, { name })
  },
  getCollection(id: number | string) {
    setToken();
    return axios.get(`/admin/v1/collections/${id}`);
  },
  createCollection(data: any) {
    setToken();
    return axios.post(`admin/v1/collections`, data);
  },
  updateCollection(id: number | string, data: any) {
    setToken();
    return axios.post(`admin/v1/collections/${id}`, data);
  },
  deleteCollection(id: string | number) {
    setToken();
    return axios.delete(`admin/v1/collections/${id}`);
  },
  moveCollectionBefore(movedId: number, beforeId: number) {
    setToken();
    return axios.post(`admin/v1/collections/${movedId}/moveBefore/${beforeId}`);
  },
  moveCollectionAfter(movedId: number, afterId: number) {
    setToken();
    return axios.post(`admin/v1/collections/${movedId}/moveAfter/${afterId}`);
  },
};

export { collectionsAPI };
