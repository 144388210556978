import type { AppProps } from 'next/app';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import 'react-calendar/dist/Calendar.css';
import 'shared/styles/globals.css';

import { StoreProvider } from '../src/context/store';

import Layout from '../components/Layout';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <StoreProvider>
      <ToastContainer />
      <Layout>
        <Component {...pageProps} />
      </Layout>
      <div id="portal" />
    </StoreProvider>
  );
}

export default MyApp;
