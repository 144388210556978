import { axios, setToken } from '../utils/axios';

import type { GoodStatus, GoodsSortingType, ICategoryMainImage, SortDirection } from 'src/models/IGood';
import type { IGoodParamsSetAddAPI } from 'src/models/IGoodPropsSet';

export enum EProductsType {
  FULL = 'FULL',
  SUMMARY = 'SUMMARY',
  BRIEF = 'BRIEF',
  ONLY_NAME = 'ONLY_NAME',
  FOR_ORDER = 'FOR_ORDER',
}

const productsAPI = {
  getGoodList(
    params: {
      [key: string]:
      | string
      | number
      | null
      | undefined
      | GoodStatus[]
      | { type: GoodsSortingType; direction: SortDirection };
    } & { format?: EProductsType }
  ) {
    setToken();
    return axios.get('admin/v1/products', {
      params,
    });
  },
  createGood(data: any) {
    setToken();
    return axios.post('admin/v1/products', data);
  },
  updateGood(id: number | string, data: any) {
    setToken();
    return axios.post(`admin/v1/products/${id}`, data);
  },
  duplicateGoods(ids: number[]) {
    setToken();
    return axios.post(`/admin/v1/products/duplicate`, { product_ids: ids });
  },
  getGoodInfo(id: number | string, params?: { format: EProductsType }) {
    setToken();
    return axios.get(`/admin/v1/products/${id}`, { params });
  },
  getGoodParams(id: number | string) {
    setToken();
    return axios.get(`/admin/v1/products/${id}/attributes`);
  },
  updateGoodParams(id: number | string, data: any) {
    setToken();
    return axios.post(`/admin/v1/products/${id}/attributes`, data);
  },
  // Good change history
  getGoodChangeHistoryList(
    id: number | string,
    params: Record<string, string | number | string[]>,
    attribute?: string
  ) {
    setToken();

    return axios.get(`/admin/v1/products/${id}/history${attribute ? '/' + attribute : ''}`, { params });
  },
  // Good Media
  getGoodImages(id: string | number, isRealImage?: boolean) {
    setToken();
    return axios.get(`admin/v1/products/${id}/${isRealImage ? 'real-' : ''}images`);
  },


  getCategoryMainImages(productId: string | number) {
    setToken();
    return axios.get<{ data: { main_images: ICategoryMainImage[] } }>(
      `/admin/v1/products/${productId}/categoriesMainImage`
    );
  },

  postCategoryMainImages(productId: string | number, main_images: ICategoryMainImage[]) {
    setToken();
    return axios.post(`/admin/v1/products/${productId}/categoriesMainImage`, { main_images });
  },

  setGoodImage(id: string | number, data: any, isRealImage?: boolean) {
    setToken();
    return axios.post(`admin/v1/products/${id}/${isRealImage ? 'real-' : ''}images`, data);
  },
  setGoodImageByUrl(id: string | number, data: any, isRealImage?: boolean) {
    setToken();
    return axios.post(`admin/v1/products/${id}/${isRealImage ? 'real-' : ''}images/byUrl`, data);
  },
  deleteGoodImage(goodId: string | number, imageId: string | number, isRealImage?: boolean) {
    setToken();
    return axios.delete(`admin/v1/products/${goodId}/${isRealImage ? 'real-' : ''}images/${imageId}`);
  },
  moveImageBefore(movedId: number, beforeId: string, goodId: number, isRealImage?: boolean) {
    setToken();
    return axios.post(`admin/v1/products/${goodId}/${isRealImage ? 'real-' : ''}images/${movedId}/moveBefore/${beforeId}`);
  },
  moveImageAfter(movedId: number, afterId: string, goodId: number, isRealImage?: boolean) {
    setToken();
    return axios.post(`admin/v1/products/${goodId}/${isRealImage ? 'real-' : ''}images/${movedId}/moveAfter/${afterId}`);
  },
  getGoodDocs(productId: number) {
    setToken();
    return axios.get(`/admin/v1/products/${productId}/documents`);
  },
  uploadGoodDocs(productId: number, data: FormData) {
    setToken();
    return axios.post(`/admin/v1/products/${productId}/documents`, data);
  },
  deleteGoodFile(productId: number) {
    setToken();
    return axios.delete(`/admin/v1/products/documents/${productId}`);
  },
  changeGoodFile(fileId: number, data: FormData) {
    setToken();
    return axios.post(`/admin/v1/products/documents/${fileId}`, data);
  },
  changeGoodsStatuses(data: { ids: number[]; status: GoodStatus }) {
    setToken();
    return axios.post(`/admin/v1/products/status`, data);
  },

  getGoodsPriceRangeLimit() {
    setToken();
    return axios.get(`/admin/v1/products/values/prices`);
  },

  getParamsSets() {
    setToken();
    return axios.get(`/admin/v1/products/export/columnSets`);
  },

  getParamsSetDetails(setId: number) {
    setToken();
    return axios.get(`/admin/v1/products/export/columnSets/${setId}`);
  },

  addNewParamsSet(set: IGoodParamsSetAddAPI) {
    setToken();
    return axios.post(`/admin/v1/products/export/columnSets`, set);
  },

  updateParamsSet(set: IGoodParamsSetAddAPI) {
    setToken();
    return axios.post(`/admin/v1/products/export/columnSets/${set.id}`, {
      columns: set.columns,
    });
  },

  deleteParamsSet(setId: number) {
    setToken();
    return axios.delete(`/admin/v1/products/export/columnSets/${setId}`);
  },

  generateBarcodeFile(count: number) {
    setToken();
    return axios.post(`/admin/v1/barcodes/generate/file`, { count }, { responseType: 'blob' });
  },
};

export { productsAPI };
