import { axios, setToken } from '../utils/axios';

import type { CallStatusType } from 'src/models/ICalls';

const callsAPI = {
  getOneClickCalls(params: { limit: number; offset: number }) {
    setToken();
    return axios.get(`/admin/v1/oneClickOrders`, { params });
  },
  getCallbacksList(params: { limit: number; offset: number }) {
    setToken();
    return axios.get(`admin/v1/callbacks`, { params });
  },
  getFeedbackList(params: { limit: number; offset: number }) {
    setToken();
    return axios.get(`admin/v1/feedback/contactForms`, { params });
  },
  getPartnershipsList(params: { limit?: number; offset?: number }) {
    setToken();
    return axios.get(`admin/v1/partnerships`, { params });
  },
  getCallbackItem(id: number) {
    setToken();
    return axios.get(`admin/v1/callbacks/${id}`);
  },
  changeCallbackStatus(id: number, params: { status: CallStatusType}) {
    setToken();
    return axios.post(`admin/v1/callbacks/${id}`, params );
  },
  getPartnershipItem(id: number) {
    setToken();
    return axios.get(`admin/v1/partnerships/${id}`);
  },
  changePartnershipStatus(id: number, params: { status: CallStatusType}) {
    setToken();
    return axios.post(`admin/v1/partnerships/${id}`, params );
  },
  getFeedbackItem(id: number) {
    setToken();
    return axios.get(`admin/v1/feedback/contactForms/${id}`);
  },
  changeFeedbackStatus(id: number, params: { status: CallStatusType}) {
    setToken();
    return axios.post(`admin/v1/feedback/contactForms/${id}`, params);
  },
};

export { callsAPI };
