import React, { createContext, useReducer } from 'react';
import { useRouter } from 'next/router';
import platform from 'platform';
import Cookies from 'js-cookie';

import type { IStore, IAction, IProvider, Actions } from './store.types';
import type { IAuthData } from '../../models/IAuthData';
import type { IUser } from '../../models/IUser';

import { api } from 'shared/api';

const initialState: IStore = {
  user: null,
  permissions: null,
};

const store = createContext<{
  state: IStore;
  actions?: Actions | any; // TODO
  dispatch?: React.Dispatch<IAction<ActionKind>>;
}>({ state: initialState });

const { Provider } = store;

export enum ActionKind {
  setUser = 'SET_USER',
}

const reducer = (oldState: IStore, action: IAction<ActionKind>): IStore => {
  switch (action.type) {
    case ActionKind.setUser: {
      return {
        ...oldState,
        user: action.payload,
      };
    }
    default:
      throw new Error();
  }
};

const useActions = (dispatch: React.Dispatch<IAction<ActionKind>>) => {
  const router = useRouter();

  const setUser = (user: IUser | null) => {
    dispatch({ type: ActionKind.setUser, payload: user });
  };

  const checkout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    Cookies.remove('authorized');
    Cookies.remove('userRole');
    setUser(null);
    router.push('/');
  };

  const login = async (data: IAuthData) => {
    try {
      const newData = {
        ...data,
        device_name: platform.name,
      };
      const response = await api.login(newData);

      await localStorage.setItem('token', response.data.data.token);
      await Cookies.set('authorized', 'true');

      const profileResponse = await api.getProfile();

      setUser(profileResponse.data.data);

      localStorage.setItem('user', JSON.stringify(profileResponse.data.data));
      Cookies.set('userRole', profileResponse.data.data.role);

      return response;
    } catch (e) {
      console.error(e);
      return e;
    }
  };

  const logout = async () => {
    try {
      await api.logout();
      checkout();
    } catch (e) {
      console.error(e);
    }
  };
  return {
    setUser,
    checkout,
    login,
    logout,
  };
};

const StoreProvider = ({ children }: IProvider) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const actions = useActions(dispatch);

  return <Provider value={{ state, dispatch, actions }}>{children}</Provider>;
};

export { store, StoreProvider };
