import { axios, setToken } from '../utils/axios';

const stickersAPI = {
  getStickers(params: { [key: string]: string | number | { content: string | undefined } | undefined }) {
    setToken();
    return axios.get(`admin/v1/stickers`, { params });
  },
  addNewSticker(data: FormData) {
    setToken();
    return axios.post(`admin/v1/stickers`, data);
  },
  updateSticker(data: FormData, id: number) {
    setToken();
    return axios.post(`admin/v1/stickers/${id}`, data);
  },
  moveStickerBefore(movedId: number, baseId: number) {
    setToken();
    return axios.post(`admin/v1/stickers/${movedId}/moveBefore/${baseId}`);
  },
  moveStickerAfter(movedId: number, baseId: number) {
    setToken();
    return axios.post(`admin/v1/stickers/${movedId}/moveAfter/${baseId}`);
  },
};

export { stickersAPI };
