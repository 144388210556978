import { FC, useContext } from 'react';
import { useRouter } from 'next/router';
import { useFormik } from 'formik';
import Cookies from 'js-cookie';
import * as yup from 'yup';

import styles from 'shared/styles/modules/inline-modal.module.scss';
import { store } from '../../../src/context/store';
import useToggle from '../../../src/hooks/useToggle';

import { IAuthData } from '../../../src/models/IAuthData';
import { IError } from '../../../src/models/IError';

import { InputText } from '@nebo-team/vobaza.ui.inputs.input-text/dist';
import { Button } from '@nebo-team/vobaza.ui.button/dist';
import { Title } from '@nebo-team/vobaza.ui.title/dist';

interface Auth {
  login: string;
  password: string;
}

const initialValues: IAuthData = {
  login: '',
  password: '',
};

const validationSchema = yup.object({
  login: yup.string().email('Не валидный email').required('Обязательное поле'),
  password: yup.string().min(8, 'Количество символов в поле должно быть не меньше 8').required('Обязательное поле'),
});

const LoginForm: FC = () => {
  const router = useRouter();
  const { actions } = useContext(store);
  const { login } = actions;
  const [isShowPassword, setIsShowPassword] = useToggle(false);

  // Form handler
  const loginHandler = async () => {
    try {
      const response = await login(values);
      if (response.status === 200) {
        const role = Cookies.get('userRole');
        switch (role) {
          case 'MAIN_CONTENT_MANAGER':
          case 'CONTENT_MANAGER':
            router.push('/users/goods');
            break;
          case 'CALL_CENTER_OPERATOR':
          case 'CALL_CENTER_MASTER':
            router.push('/orders');
            break;
          default:
            router.push('/users/admins');
        }
      }
      if (response?.response?.data?.errors) {
        const errs = response.response.data.errors;
        const backErrors = {} as any;

        errs.forEach((err: IError) => {
          err.source && err.source !== ''
            ? (backErrors[err.source] = err.title)
            : (backErrors.password = err.title ? err.title : 'Непредвиденная ошибка, попробуйте ещё раз');
        });
        setErrors(backErrors);
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  const { values, setFieldValue, validateField, errors, handleSubmit, setErrors } = useFormik<Auth>({
    initialValues,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: loginHandler,
  });

  const handleChange = async (e: any) => {
    await setFieldValue(e.target.name, e.target.value);
    validateField(e.target.name);
  };

  const handleBlur = async (e: any) => {
    validateField(e.target.name);
  };

  return (
    <div className={styles.inlineModal}>
      <form className={styles.inlineModalContent}>
        <Title element="h2" className={styles.inlineModalTitle}>
          Авторизация
        </Title>

        <div className={styles.inlineModalItem}>
          <InputText
            label="Email (логин)"
            name="login"
            value={values.login}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors?.login}
          />
        </div>

        <div className={styles.inlineModalItem}>
          <InputText
            type={isShowPassword ? 'text' : 'password'}
            label="Пароль"
            name="password"
            value={values.password}
            error={errors?.password}
            icon={isShowPassword ? 'Eye' : 'CloseEye'}
            onChange={handleChange}
            onBlur={handleBlur}
            onClickIcon={setIsShowPassword}
          />
        </div>

        <Button className={styles.inlineModalButton} isFullScreen text="Войти" onClick={() => handleSubmit()} />
      </form>
    </div>
  );
};

export default LoginForm;
