import { axios, setToken } from '../utils/axios';

const bannersAPI = {
  getBannersList(type: string) {
    setToken();
    return axios.get(`admin/v1/banners/`, { params: { type } });
  },
  getBanner(id: number | string) {
    setToken();
    return axios.get(`/admin/v1/banners/${id}`);
  },
  createBanner(data: any) {
    setToken();
    return axios.post(`admin/v1/banners/`, data);
  },
  updateBanner(id: number | string, data: any) {
    setToken();
    return axios.post(`admin/v1/banners/${id}`, data);
  },
  deleteBanner(bannerId: string | number) {
    setToken();
    return axios.delete(`admin/v1/banners/${bannerId}`);
  },
  moveBannerBefore(movedId: number, beforeId: number) {
    setToken();
    return axios.post(`admin/v1/banners/${movedId}/moveBefore/${beforeId}`);
  },
  moveBannerAfter(movedId: number, afterId: number) {
    setToken();
    return axios.post(`admin/v1/banners/${movedId}/moveAfter/${afterId}`);
  },
};

export { bannersAPI };
