import { axios, setToken } from '../../utils/axios';

import type { IGoodsBrand, IVobazaMark } from 'src/models/IGood';
import type { ICategoryFilter } from '../../../../src/models/ICategoryFilter';

const categoryFiltersAPI = {
  getCategoryFilterSourses(categoryId: string | number) {
    setToken();
    return axios.get(`admin/v1/categories/${categoryId}/filters/sources`);
  },
  getCategoryFilterList(categoryId: string | number) {
    setToken();
    return axios.get(`admin/v1/categories/${categoryId}/filters`);
  },
  createCategoryFilter(categoryId: string | number, data: Omit<ICategoryFilter, 'id'>) {
    setToken();
    return axios.post(`admin/v1/categories/${categoryId}/filters`, data);
  },
  getCategoryFilter(id: string | number) {
    setToken();
    return axios.get(`admin/v1/categories/filters/${id}`);
  },
  deleteCategoryFilter(id: string | number) {
    setToken();
    return axios.delete(`admin/v1/categories/filters/${id}`);
  },
  updateCategoryFilter(id: string | number, data: Omit<ICategoryFilter, 'id'>) {
    setToken();
    return axios.post(`admin/v1/categories/filters/${id}`, data);
  },
  moveCategoryFilterBefore(movedId: number, beforeId: number) {
    setToken();
    return axios.post(`admin/v1/categories/filters/${movedId}/moveBefore/${beforeId}`);
  },
  moveCategoryFilterAfter(movedId: number, afterId: number) {
    setToken();
    return axios.post(`admin/v1/categories/filters/${movedId}/moveAfter/${afterId}`);
  },
  getProductsBrands() {
    setToken();
    return axios.get<{ data: IGoodsBrand }>(`admin/v1/products/values/brands`);
  },
  getVobazaMarks() {
    setToken();
    return axios.get<{ data: IVobazaMark }>('admin/v1/products/values/vobazaMarks');
  },
  getCrossdockMerchants() {
    setToken();
    return axios.get<{ data: string[] }>('/admin/v1/orders/cross-dock/values/merchants');
  },
};

export { categoryFiltersAPI };
