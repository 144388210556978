import type { NextPage } from 'next';
import LoginForm from '../components/Auth/LoginForm';

const Index: NextPage = () => {
  return (
    <>
      <LoginForm />
    </>
  );
};

export default Index;
