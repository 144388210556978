import { ICrossDocOrder } from 'src/models/IOrder/ICrossdoc';
import { axios, setToken } from '../../utils/axios';

const crossDocOrdersAPI = {
  getCrossDocOrdersList(params: { [key: string]: string[] | string }) {
    setToken();
    return axios.get<{ data: ICrossDocOrder[] }>('/admin/v1/orders/shipment-method/cross-dock', { params });
  },
};

export { crossDocOrdersAPI };
