import { axios, setToken } from '../../utils/axios';

import type { IProductsImportIssues } from 'components/Goods/Upload/ProductsImportIssues';
import type { ICategory, ICategoryRequestParams } from '../../../../src/models/ICategory';
import type { ITask, ITaskIssue, TaskType } from 'src/models/ITask';
import type { GoodStatus } from 'src/models/IGood';
import type { ISetParamItemAPI } from 'src/models/IGoodPropsSet';

import { trendingCategoriesAPI } from './trendings';
import { categoryFiltersAPI } from './filters';
import { variationDisplayAPI } from './variationDisplay';

export interface IGetBackgroundTaskIssuesParams {
  limit: number;
  offset?: number;
  subtask_types?: TaskType[];
  'filter[type]'?: 'ERROR' | 'WARNING';
}
export interface IExportProductsParams {
  columns?: ISetParamItemAPI[];
  filters?: {
    text?: string;
    merchant_id?: number;
    category_ids?: number[];
    price?: {
      min: number;
      max: number;
    };
    status?: GoodStatus[];
    created_start_from?: string;
    created_end_to?: string;
    updated_start_from?: string;
    updated_end_to?: string;
    brands?: string[];
  };
  export_set_id?: number;
}

const categoriesAPI = {
  createGoodsCategory(data: any, highLevelCategoryId: string | number | null = null) {
    let url = `admin/v1/categories`;

    if (highLevelCategoryId !== null) url += `/${highLevelCategoryId}/children`;

    setToken();
    return axios.post(url, data);
  },
  getCategory(id: string | number, params = {}) {
    setToken();
    return axios.get<{ data: ICategory }>(`admin/v1/categories/${id}`, { params });
  },
  getCategories(params: ICategoryRequestParams = {}) {
    setToken();
    return axios.get<{ data: ICategory[] }>('admin/v1/categories', { params });
  },
  getCategoriesFlatList(params: { [key: string]: string } = {}) {
    setToken();
    return axios.get('admin/v1/categories/search', { params });
  },
  updateCategory(id: string | number, data: any) {
    setToken();
    return axios.post(`admin/v1/categories/${id}`, data);
  },
  deleteCategory(id: string | number) {
    setToken();
    return axios.delete(`admin/v1/categories/${id}`);
  },
  moveCategoryBefore(movedId: number, beforeId: number) {
    setToken();
    return axios.post(`admin/v1/categories/${movedId}/moveBefore/${beforeId}`);
  },
  moveCategoryAfter(movedId: number, afterId: number) {
    setToken();
    return axios.post(`admin/v1/categories/${movedId}/moveAfter/${afterId}`);
  },
  moveCategoryAsRoot(categoryId: number) {
    setToken();
    return axios.post(`/admin/v1/categories/${categoryId}/moveAsChild`);
  },
  moveCategoryAsChild(movedId: number, parentId: number) {
    setToken();
    return axios.post(`admin/v1/categories/${movedId}/moveAsChild/${parentId}`);
  },
  addCategoryByInfomodel(id: string | number, file: FormData) {
    setToken();
    return axios.post(`admin/v1/categories/${id}/attributes/byFile`, file);
  },
  addProductsByFile(file: FormData) {
    setToken();
    return axios.post<{ data: IProductsImportIssues }>(`admin/v3/products/byFile`, file);
  },
  uploadCategoryTags(file: FormData) {
    setToken();
    return axios.post('admin/v1/categories/tags/byFile', file);
  },
  downloadCategoryTags() {
    setToken();
    return axios.post('admin/v1/categories/tags/export', null, { responseType: 'blob' });
  },

  createNewUnload() {
    setToken();
    return axios.post('admin/v1/categories/tags/export');
  },
  getBackgroundTask(taskId: number | string) {
    setToken();
    return axios.get<{ data: ITask }>(`admin/v1/tasks/${taskId}`);
  },
  getBackgroundTaskIssues(taskId: number | string, params: IGetBackgroundTaskIssuesParams) {
    interface ITaskIssuesData {
      data: ITaskIssue[];
      meta: { list: { count: number; pages_count: number } };
    }

    setToken();
    return axios.get<ITaskIssuesData>(`admin/v1/tasks/${taskId}/errors`, { params });
  },

  exportProducts(data: IExportProductsParams) {
    setToken();
    return axios.post(`admin/v2/products/export`, data);
  },

  getCurrentExportTags() {
    setToken();
    return axios.get('admin/v1/categories/tags/export/current');
  },

  ...categoryFiltersAPI,
  ...trendingCategoriesAPI,
  ...variationDisplayAPI,
};

export { categoriesAPI };
