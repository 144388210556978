import { axios, setToken } from '../utils/axios';
import { IHub } from 'src/models/IHub';

const hubsAPI = {
  getHubs(params: { limit: number; offset: number }) {
    setToken();
    return axios.get(`admin/v1/categories/hubs`, { params });
  },
  createHub(hubItem: IHub) {
    setToken();
    return axios.post(`admin/v1/categories/hubs`, hubItem);
  },
  getHubsInfo(id: string) {
    setToken();
    return axios.get(`admin/v1/categories/hubs/${id}`);
  },
  editHub(id: string | number, hub: IHub) {
    setToken();
    return axios.post(`admin/v1/categories/hubs/${id}`, hub);
  },
  removeHub(id: number) {
    setToken();
    return axios.delete(`admin/v1/categories/hubs/${id}`);
  },
};

export { hubsAPI };
