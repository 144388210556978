import { axios, setToken } from '../utils/axios';

import type { IAdmin, IRequestChangeAdmin } from 'src/models/IAdmin';
import type { IAdminLogsRequest } from 'src/models/IAdminLogs';

const adminsAPI = {
  getAdminsList(params?: Record<string, string>) {
    setToken();
    return axios.get('admin/v1/admins', {params});
  },
  getAdmin({ id }: { id: string }) {
    setToken();
    return axios.get(`admin/v1/admins/${id}`);
  },
  getAdminLogs({ limit, offset, filters }: IAdminLogsRequest) {
    setToken();
    return axios.get(`admin/v1/logs`, {
      params: {
        limit,
        offset,
        ...filters,
      },
    });
  },
  addAdmin(value: IAdmin) {
    setToken();
    return axios.post('admin/v1/admins', value);
  },
  deleteAdmin({ id }: { id: string }) {
    setToken();
    return axios.delete(`admin/v1/admins/${id}`);
  },
  changeAdmin({ id, adminData }: { id: string; adminData: IRequestChangeAdmin }) {
    setToken();
    return axios.post(`admin/v1/admins/${id}`, adminData);
  },
};

export { adminsAPI };
