import { axios, setToken } from '../utils/axios';

import type { IAuthData } from '../../../src/models/IAuthData';
import type { IUser } from 'src/models/IUser';

const authAPI = {
  login(auth: IAuthData) {
    return axios.post('admin/v1/token', auth);
  },
  logout() {
    setToken();
    return axios.delete('admin/v1/token');
  },
  getProfile() {
    setToken();
    return axios.get<{ data: IUser }>('/admin/v1/profile');
  },
};

export { authAPI };
