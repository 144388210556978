import { axios, setToken } from 'shared/api/utils/axios';

import {
  AttributeDisplay,
  AttributeDisplayListItem,
} from 'components/widgets/category/AttributeDisplayModal/lib/schema';

interface GetAttributesForDisplay {
  data: { id: number; name: string }[];
}

export const variationDisplayAPI = {
  /** Характеристики категории, которые можно добавить в список настроек отображения для объединений товаров */
  getAttributesForDisplay: async (categoryId: number) => {
    setToken();
    return axios.get<GetAttributesForDisplay>(`admin/v1/categories/${categoryId}/variantAttributes/attributes`);
  },
  /** Характеристики для вариаций у категории */
  getAttributesDisplay: async (categoryId: number) => {
    setToken();
    return axios.get<{ data: AttributeDisplayListItem[] }>(`admin/v1/categories/${categoryId}/variantAttributes`);
  },
  /** Добавить характеристику для вариаций категории */
  addAttributeDisplay: async (categoryId: number, data: AttributeDisplay) => {
    setToken();
    return axios.post(`admin/v1/categories/${categoryId}/variantAttributes`, data);
  },
  /** Обновить характеристику для вариаций у категории */
  updateAttributeDisplay: async (categoryId: number, attributeId: number, data: AttributeDisplay) => {
    setToken();
    return axios.post(`admin/v1/categories/${categoryId}/variantAttributes/${attributeId}`, data);
  },
  /** Удалить характеристику для вариаций у категории */
  removeAttributeDisplay: async (categoryId: number, attributeId: number) => {
    setToken();
    return axios.delete(`admin/v1/categories/${categoryId}/variantAttributes/${attributeId}`);
  },
};
