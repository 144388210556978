import { axios, setToken } from '../utils/axios';

import type { IGoodsParamRequest } from '../../../src/models/IGoodsParam';

const attributesAPI = {
  getGoodsParamList(params: { [key: string]: string | number | Array<string | number> }) {
    setToken();
    return axios.get('admin/v1/attributes', {
      params,
    });
  },

  getGoodsPropsList() {
    setToken();
    return axios.get('admin/v2/products/export/properties');
  },

  createGoodsParam(data: IGoodsParamRequest) {
    setToken();
    return axios.post('admin/v1/attributes', data);
  },
  updateGoodsParam(id: number | string, data: IGoodsParamRequest) {
    setToken();
    return axios.post(`admin/v1/attributes/${id}`, data);
  },
  deleteGoodsParam(id: string | number) {
    setToken();
    return axios.delete(`admin/v1/attributes/${id}`);
  },
  getGoodsParamInfo(id: string | number) {
    setToken();
    return axios.get(`admin/v1/attributes/${id}`);
  },
};

export { attributesAPI };
