import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';

import { store } from '../../src/context/store';
import { routes, goodsMerchantRoutes } from '../../src/const/routes';
import { useInterceptors } from '../../src/hooks/useInterceptors';

import { Header } from '@nebo-team/vobaza.layout.header/dist';
import { Navigition } from '@nebo-team/vobaza.layout.navigition/dist';
import { MainHead } from './MainHead';

import styles from './styles.module.scss';

interface ILayoutChildren {
  children: React.ReactNode;
}

const withoutNavigation = (pathname: string) => {
  const routes = [
    '/goods/merchant/[id]/add/new',
    '/goods/merchant/[id]/good/[goodId]',
    '/orders',
    '/management/hubs/add/info',
    '/management/hubs/[id]/info',
    '/management/hubs/[id]/links',
  ];

  for (const route of routes) {
    if (pathname.startsWith(route)) return true;
  }

  return false;
};

export default function Layout({ children }: ILayoutChildren) {
  const [isLoginChecked, setIsLoginChecked] = useState(false);
  const [currentRoutes, setCurrentRoutes] = useState<any[]>([]);

  const router = useRouter();
  const { pathname, asPath } = router;

  const { state, actions } = useContext(store);
  const { user } = state;
  const { setUser, checkout, logout } = actions;
  const role = Cookies.get('userRole');

  const onLogout = async () => {
    await logout();
  };

  const getRoutes = () => {
    if (pathname.includes('/goods/merchant')) return goodsMerchantRoutes((router.query.id || '').toString());
    else {
      const currentRoute = currentRoutes.find((route: any) => pathname.includes(route.href));

      if (currentRoute) {
        if (!Array.isArray(currentRoute.routes) || !currentRoute.routes.length) return [];

        return [currentRoute];
      }
    }
    return null;
  };

  const getMainLink = () => {
    if (!process.browser) return '/users/admins';
    const role = Cookies.get('userRole');
    switch (role) {
      case 'MAIN_CONTENT_MANAGER':
      case 'CONTENT_MANAGER':
        return '/users/goods';
      case 'CALL_CENTER_MASTER':
      case 'CALL_CENTER_OPERATOR':
        return '/orders';
      default:
        return '/users/admins';
    }
  };

  useEffect(() => {
    const authorized = Cookies.get('authorized');

    if (localStorage.getItem('token') && !user) {
      const cachedUser = localStorage.getItem('user');

      if (cachedUser) {
        Cookies.set('userRole', JSON.parse(cachedUser).role, { expires: new Date('Fri, 31 Dec 9999 23:59:59 GMT') });
        setUser(JSON.parse(cachedUser) || null);
      } else {
        checkout();
      }
    }

    if (!user) {
      setCurrentRoutes([]);
    } else {
      const role = Cookies.get('userRole');
      if (role) {
        setCurrentRoutes(routes.filter((route) => !route.notAllowedRoles?.includes(role || '')));
      }
    }

    if (authorized === undefined) {
      checkout();
    }
  }, [user]);

  useEffect(() => {
    if (router.pathname === '/') {
      if (localStorage.getItem('token')) {
        router.push('/users/admins');
      } else {
        setIsLoginChecked(true);
      }
    } else {
      if (localStorage.getItem('token')) {
        setIsLoginChecked(true);
      } else {
        checkout();
      }
    }
  }, [router]);

  useInterceptors({
    onError(err) {
      if (err.response && err.response.status === 401) {
        checkout();
      }
    },
  });

  const menuRoutes = getRoutes();
  const withSubmenu = menuRoutes && !!menuRoutes.length;

  /**Отображать этикетки и хабы, определенным ролям*/
  const getFilteredMenuRoutes = (menuRoutes: any[] | null) => {
    return menuRoutes?.length
      ? [
          {
            ...menuRoutes[0],
            routes: menuRoutes[0]?.routes?.filter(
              (el: any) =>
                !['Этикетки товаров', 'Страницы-хабы'].includes(el.value) ||
                (role && ['SUPER_ADMIN', 'MAIN_CONTENT_MANAGER'].includes(role) && el.value === 'Этикетки товаров') ||
                (role &&
                  ['SUPER_ADMIN', 'MAIN_CONTENT_MANAGER', 'CONTENT_MANAGER'].includes(role) &&
                  el.value === 'Страницы-хабы')
            ),
          },
        ]
      : [];
  };

  const filteredMenuRoutes = getFilteredMenuRoutes(menuRoutes);

  const isWideContent = router.pathname === '/orders';

  return (
    <>
      <MainHead />
      <div className={styles.layout}>
        <Header
          logoLink={getMainLink()}
          user={user as any}
          onLogout={onLogout}
          links={currentRoutes}
          currentPath={pathname}
        />

        {isLoginChecked && (
          <div className={`${styles.container} ${isWideContent ? styles.containerWide : ''}`}>
            {withSubmenu && !withoutNavigation(pathname) && (
              <div className={styles.navigation}>
                <Navigition routesList={filteredMenuRoutes} currentPath={asPath} />
              </div>
            )}
            <div className={styles.content}>{children}</div>
          </div>
        )}
      </div>
    </>
  );
}
