import { axios, setToken } from './utils/axios';

import { authAPI } from './modules/auth';
import { adminsAPI } from './modules/admins';
import { merchantsAPI } from './modules/merchants';
import { categoriesAPI } from './modules/categories';
import { comparisonStocksAPI } from './modules/comparison-stocks';
import { attributesAPI } from './modules/attributes';
import { productsAPI } from './modules/products';
import { ordersAPI } from './modules/orders';
import { yandexDeliveryAPI } from './modules/orders/yandex/endpoints';
import { bannersAPI } from './modules/banners';
import { collectionsAPI } from './modules/collections';
import { dictionariesAPI } from './modules/dictionaries';
import { customersAPI } from './modules/customers';
import { callsAPI } from './modules/calls';
import { dadataApi } from './modules/dadata';
import { productsVariationsAPI } from './modules/productsVariations';
import { feedsAPI } from './modules/feeds';
import { stickersAPI } from './modules/stickers';
import { hubsAPI } from './modules/hubs';
import { cacheAPI } from './modules/cache';
import { crossDocOrdersAPI } from './modules/cross-dock';

export const api = {
  ...authAPI,
  ...adminsAPI,
  ...merchantsAPI,
  ...categoriesAPI,
  ...comparisonStocksAPI,
  ...attributesAPI,
  ...productsAPI,
  ...ordersAPI,
  ...yandexDeliveryAPI,
  ...bannersAPI,
  ...collectionsAPI,
  ...dictionariesAPI,
  ...customersAPI,
  ...callsAPI,
  ...dadataApi,
  ...productsVariationsAPI,
  ...feedsAPI,
  ...stickersAPI,
  ...hubsAPI,
  ...cacheAPI,
  ...crossDocOrdersAPI,
  // Mailing
  getMailSubscriptions(params: any) {
    setToken();
    return axios.get(`admin/v1/subscriptions`, { params });
  },
  // Generate and return new unique barcode
  generateBarcode() {
    setToken();
    return axios.post('/admin/v1/barcodes/generate');
  },
  generateBarcodes(data: { product_ids: number[] }) {
    setToken();
    return axios.post('/admin/v1/barcodes/fillInProducts', data);
  },
  downloadFile(url: string) {
    setToken();
    return axios.get(url, { responseType: 'blob' });
  },
  downloadStorageFile( fileLink: string, storage: string) {
    const params = { path: fileLink }
    setToken();
    return axios.get(`/download/${storage}`, {params, responseType: "blob"});
  },
};
