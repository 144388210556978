import { axios, setToken } from '../utils/axios';

export const productsVariationsAPI = {
  getAvailableVariantParams(productId: number | string) {
    setToken();
    return axios.get(`/admin/v1/products/${productId}/variant/attributes/all`);
  },
  getCurrentVariantParams(productId: string | number) {
    setToken();
    return axios.get(`/admin/v1/products/${productId}/variant/attributes`);
  },
  addVariantParams(productId: string | number, data: { attribute_id: number; category_id: number }[], name: string) {
    setToken();
    return axios.post(`/admin/v1/products/${productId}/variant/attributes`, {
      name,
      attributes: data,
    });
  },
  getAvailableVariantProducts(productId: number | string, params: { [key: string]: string | number } = {}) {
    setToken();
    return axios.get(`/admin/v1/products/${productId}/variant/products/all`, { params });
  },
  getCurrentVariantProducts(productId: number | string) {
    setToken();
    return axios.get(`/admin/v1/products/${productId}/variant/products`);
  },
  getCurrentProductVariant(productId: number | string) {
    setToken();
    return axios.get(`/admin/v1/products/${productId}/variant`);
  },
  addVariantProducts(productId: number | string, productIds: number[], isAddAllSearchItems?: boolean, search?: string) {
    setToken();
    const data = {
      ...(isAddAllSearchItems ? { filter: { text: search } } : { product_ids: productIds }),
    };

    return axios.post(`/admin/v1/products/${productId}/variant/products`, data);
  },
  removeVariantGoods(productId: number | string, removingProductsId: number | string) {
    setToken();
    return axios.delete(`/admin/v1/products/${productId}/variant/products/${removingProductsId}`);
  },
  deleteVariation: (productId: number | string) => {
    setToken();
    return axios.delete(`admin/v1/products/${productId}/variant`);
  },
};
