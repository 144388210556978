import { axios, setToken } from '../utils/axios';

import type { IAddDictionaryItemRequest, IDictionaryItem, IDictionaryOrderSourceForm } from '../../../src/models/IDictionary';

interface IGetDictionary {
  name: string;
  code: string;
  items: IDictionaryItem[];
}

const dictionariesAPI = {
  getDictionaries(dic: string, params: any = null) {
    return axios.get<{ data: IGetDictionary }>(`v1/dictionaries/${dic}`, { params });
  },
  addDictionaryItem(dic: string, data: IAddDictionaryItemRequest) {
    setToken();
    return axios.post(`admin/v1/dictionaries/${dic}`, data);
  },
  getOrderSources() {
    setToken();
    return axios.get(`admin/v1/order-sources`);
  },
  getOrderSource(id: string | number) {
    setToken();
    return axios.get(`admin/v1/order-sources/${id}`);
  },
  updateOrderSource(id: string | number, data: IDictionaryOrderSourceForm) {
    const { name, active, market } = data;
    setToken();
    return axios.post(`admin/v1/order-sources/${id}`, { name, active: active.code, market });
  },
  createOrderSource(data: IDictionaryOrderSourceForm) {
    setToken();
    return axios.post(`admin/v1/order-sources`, { ...data, active: data.active.code });
  },
  moveOrderSourceBefore(movedId: number, beforeId: number) {
    setToken();
    return axios.post(`admin/v1/order-sources/${movedId}/moveBefore/${beforeId}`);
  },
  moveOrderSourceAfter(movedId: number, afterId: number) {
    setToken();
    return axios.post(`admin/v1/order-sources/${movedId}/moveAfter/${afterId}`);
  },
};

export { dictionariesAPI };
