import { IYandexDeliveryOffer, IApproveYandexDelivery } from 'src/models/IOrder/index';
import type { IYandexDeliveryDate } from 'src/models/IOrder';

import { axios, setToken } from '../../../utils/axios';

const yandexDeliveryAPI = {
  getYandexDeliveryDates(values: IYandexDeliveryDate) {
    setToken();
    return axios.post('/admin/v1/company-transport/yandex/time-interval', values);
  },
  getYandexDeliveryPrice(values: IYandexDeliveryOffer) {
    setToken();
    return axios.post('admin/v1/company-transport/yandex/offer', values);
  },
  cancelYandexDelivery(objectId: any, values: IYandexDeliveryOffer) {
    setToken();
    return axios.post(`admin/v1/orders/${objectId}/company-transport/yandex/offer/cancel`, values);
  },
  acceptYandexDelivery(objectId: any, values: IYandexDeliveryOffer) {
    setToken();
    return axios.post(`admin/v1/orders/${objectId}/company-transport/yandex/offer/accept`, values);
  },
  approveYandexDelivery(offerId: number, values: IApproveYandexDelivery) {
    setToken();
    return axios.post(`/admin/v1/company-transport/yandex/offer/${offerId}`, values);
  },
};

export { yandexDeliveryAPI };
